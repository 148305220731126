import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import TitleBar from "../TitleBar";
import HeroBar from "../HeroBar";
import { getUserById, updateUser } from "../../api/apiUser";

const EditForm = () => {
  const { id } = useParams();
  const [name, setName] = useState("");
  const [store, setStore] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [password, setPassword] = useState("");
  const [confPassword, setConfPassword] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    fetchUserDetails(id);
  }, [id]);

  const fetchUserDetails = async (userId) => {
    try {
      const userData = await getUserById(userId);
      setName(userData.name);
      setStore(userData.store);
      setEmail(userData.email);
      setRole(userData.role);
      setPassword("");
      setConfPassword("");
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  };

  const handleUpdateUser = async () => {
    try {
      await updateUser(id, {
        name,
        store,
        email,
        password,
        confPassword,
        role,
      });
      Swal.fire("Success!", "User updated successfully.", "success");
      navigate("/users");
    } catch (error) {
      Swal.fire("Error!", "Failed to update user.", error);
    }
  };

  return (
    <>
      <TitleBar titles={["Home", "Users"]} />
      <HeroBar title="Edit Users" />
      <section className="section is-main-section">
        <div className="card is-shadowless">
          <div className="card-content">
            <div className="content">
              <div className="field">
                <label className="label">Name User</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Name"
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Name Store</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={store}
                    onChange={(e) => setStore(e.target.value)}
                    placeholder="Name Store"
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Email</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Email"
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Role</label>
                <div className="control">
                  <div className="select is-fullwidth">
                    <select
                      value={role}
                      onChange={(e) => setRole(e.target.value)}>
                      <option value="">Pilih Role...</option>
                      <option value="admin">Admin</option>
                      <option value="user">User</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="field">
                <div className="control">
                  <button
                    onClick={handleUpdateUser}
                    className="button is-success">
                    Update
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default EditForm;
