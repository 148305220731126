import TitleBar from "../TitleBar";
import HeroBar from "../HeroBar";
import { useSelector } from "react-redux";

const Overview = () => {
  const { user } = useSelector((state) => state.auth);

  return (
    <div>
      <TitleBar titles={["Account", "Overview"]} />
      <HeroBar title="Overview" />
      <section className="section is-main-section">
        <div className="container">
          <div className="card is-shadowless">
            <div className="card-content">
              <div className="content">
                <div className="card-body pt-9 pb-0">
                  <div className="columns">
                    <div className="column is-3">
                      <div className="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
                        <img
                          src="https://preview.keenthemes.com/metronic8/react/demo1//media/avatars/300-1.jpg"
                          alt="Metronic"
                        />
                      </div>
                    </div>
                    <div className="column">
                      <div className="colums">
                        <div className="grid">
                          <div className="cell">
                            <a href="!#" className="title is-5">
                              Max Smith
                            </a>
                          </div>
                          <div className="cell">
                            <i className="mdi mdi-ab-testing"></i>
                          </div>
                          <div className="cell">
                            <span className="tag is-primary">
                              Upgrade to Pro
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Overview;
