import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LoginUser, reset } from "../features/authSlice";
import "../assets/css/login.css";
import Swal from "sweetalert2";
import Image from "../logo.png";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, isError, isSuccess, isLoading, message } = useSelector(
    (state) => state.auth
  );

  useEffect(() => {
    if (user || isSuccess) {
      navigate("/dashboard");
    }
    dispatch(reset());
  }, [user, isSuccess, dispatch, navigate]);

  const Auth = (e) => {
    e.preventDefault();
    dispatch(LoginUser({ email, password }));
  };

  useEffect(() => {
    if (isError) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: message,
      });
    }
  }, [isError, message]);

  return (
    <section className="login-bg">
      {" "}
      {/* Terapkan kelas login-bg di sini */}
      <div className="hero is-fullheight is-fullwidth">
        <div className="hero-body hero-login">
          <div className="container">
            <div className="columns is-centered">
              <div className="column is-6">
                <div className="logo-favori">
                  <img src={Image} alt="logo" className="logo" />
                </div>
                <div className="title-app">Favori Complaint v.01</div>

                <form onSubmit={Auth} className="box">
                  <h1 className="title is-2 login">Login</h1>
                  <div className="field">
                    <label className="label">Email</label>
                    <div className="control">
                      <input
                        type="text"
                        className="input"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Email"
                      />
                    </div>
                  </div>
                  <div className="field">
                    <label className="label">Password</label>
                    <div className="control">
                      <input
                        type="password"
                        className="input"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="******"
                      />
                    </div>
                  </div>
                  <div className="field mt-5">
                    <button
                      type="submit"
                      className={`button is-success is-fullwidth ${
                        isLoading && "is-loading"
                      }`}>
                      {isLoading ? "Loading..." : "Login"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
