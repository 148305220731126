import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { fetchUsers } from "../../api/apiUser";
import { fetchAllProducts } from "../../api/apiProduct";
import { fetchComplaintCategories } from "../../api/apiCategoryComplaint";
import { fetchTypeComplaints } from "../../api/apiTypeComplaint";
import { fetchComplaintDetailsPrint } from "../../api/apiComplaint";
import Image from "../Complaint/favori-icon.png";

const PrintComplaint = () => {
  const [reportDate, setReportDate] = useState("");
  const [complaintName, setComplaintName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [description, setDescription] = useState("");
  const [userCreate, setUserCreate] = useState("");
  const [SelectedUserDelegate, setSelectedUserDelegate] = useState("");
  const [userDelegates, setUserDelegates] = useState([]);
  const [selectedType, setSelectedType] = useState("");
  const [sumber, setSumber] = useState("");
  const [userDelegate, setDelegate] = useState("");
  const [typesRes, setTypesRes] = useState([]);
  const [items, setItems] = useState([
    {
      productId: "",
      skuProduct: "",
      nameProduct: "",
      quantity: "",
      notes: "",
      complaintCategoryId: "",
      photoComplaint: "",
      photoProductionProduct: "",
    },
  ]);

  const [msg, setMsg] = useState("");
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    fetchResources();
    fetchComplaintDetailsPrint(
      id,
      setReportDate,
      setComplaintName,
      setPhoneNumber,
      setAddress,
      setSelectedType,
      setSumber,
      setSelectedUserDelegate,
      setDelegate,
      setDescription,
      setUserCreate,
      setItems
    );
  }, [id]);

  const fetchResources = async () => {
    try {
      const [usersRes, productsRes, categoriesRes, typesRes] =
        await Promise.all([
          fetchUsers(),
          fetchAllProducts(),
          fetchComplaintCategories(),
          fetchTypeComplaints(),
        ]);
      setUserDelegates(usersRes);
      setTypesRes(typesRes);
    } catch (error) {
      console.error("Error fetching resources:", error);
    }
  };

  return (
    <div>
      <section className="section is-main-section print has-background-white">
        <div className="content">
          <p
            className="has-text-centered"
            style={{ fontSize: "40px", fontWeight: "700" }}>
            Formulir Komplain
          </p>
          <div className="columns">
            <div className="columns" style={{ width: "30vh" }}>
              <div className="column">
                <img
                  src={Image}
                  alt="logo"
                  style={{ width: "262px", height: "92px" }}
                />
              </div>
            </div>
            <div className="column" style={{ paddingRight: "30px" }}>
              <div className="">
                <label className="label">Sumber</label>

                <span>{sumber}</span>
              </div>
              <div className="">
                <label className="label">Nama Sumber</label>
                <div className="control">
                  <span>{complaintName}</span>
                </div>
              </div>
              <div className="">
                <label className="label">Alamat</label>
                <div className="control">
                  <span>{address}</span>
                </div>
              </div>
            </div>
            <div className="column">
              <div className="">
                <label className="label">Tanggal Laporan</label>
                <div className="control">
                  <span>{reportDate}</span>
                </div>
              </div>
              <div className="">
                <label className="label">Nomor Telepon</label>
                <div className="control">
                  <span>{phoneNumber}</span>
                </div>
              </div>
              <div className="">
                <label className="label">User Delegasi</label>
                <div className="control">{userDelegate}</div>
              </div>
            </div>
          </div>
          <table className="table is-fullwidth">
            <thead>
              <tr>
                <th>No.</th>
                <th>Produk</th>
                <th>Jumlah</th>
                <th>Kategori</th>
                <th>Catatan</th>
                <th>Kode Produksi</th>
              </tr>
            </thead>
            <tbody>
              {items.map(
                (
                  {
                    productId,
                    quantity,
                    skuProduct,
                    nameProduct,
                    notes,
                    complaintCategoryName,
                    productionCode,
                  },
                  index
                ) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>
                      <div className="field">
                        <div className="control">
                          <span>
                            {skuProduct} - {nameProduct}
                          </span>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="field">
                        <div className="control">
                          <span>{quantity}</span>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="field">
                        <div className="control">
                          <span>{complaintCategoryName}</span>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="field">
                        <div className="control">
                          <span>{notes}</span>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="control">{productionCode}</div>
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>
          <div className="field">
            <label className="label">Keterangan Tambahan</label>
            <div className="control">
              <span> {description}</span>
            </div>
          </div>
        </div>
        <div className="columns" style={{ paddingTop: "30px" }}>
          <div className="column">
            <div className="">
              <label className="label">Dibuat Oleh</label>
              <br />
              <label className="label">({userCreate})</label>
            </div>
          </div>
          <div className="column" style={{ paddingRight: "30px" }}>
            <div className="">
              <label className="label">Verifikasi Oleh</label>
              <br />
              <label className="label">(________________)</label>
            </div>
          </div>
        </div>
      </section>
      {/* <div className="field is-grouped">
        <div className="control">
          <button
            type="button"
            className="button is-primary"
            onClick={printComplaintDetails}>
            Print
          </button>
        </div>
      </div> */}
    </div>
  );
};

export default PrintComplaint;
