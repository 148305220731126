import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import TitleBar from "../TitleBar";
import HeroBar from "../HeroBar";
import { fetchCustomers, deleteCustomer } from "../../api/apiCustomer";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import Pagination from "../../features/Pagination";

const CustomerList = () => {
  const [customers, setCustomers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    getCustomers(currentPage);
  }, [currentPage]);

  const getCustomers = async (page) => {
    try {
      const { customers: customersData, total_pages: total } =
        await fetchCustomers(page);
      setCustomers(customersData);
      setTotalPages(total);
    } catch (error) {
      console.error("Error fetching customers:", error);
    }
  };

  const confirmDeleteCustomer = async (customerId) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    });

    if (result.isConfirmed) {
      deleteCustomer(customerId)
        .then(() => {
          getCustomers(currentPage);
          Swal.fire("Deleted!", "Customer has been deleted.", "success");
        })
        .catch((error) => {
          console.error("Error deleting customer:", error);
          Swal.fire("Error!", "Failed to delete customer.", "error");
        });
    }
  };

  return (
    <div>
      <TitleBar titles={["Home", "Customers"]} />
      <HeroBar title="Customers" />
      <section className="section is-main-section">
        <Link to="/customers/add" className="button is-primary mb-2">
          Add Customers
        </Link>
        <div className="card has-table has-mobile-sort-spaced">
          <header className="card-header">
            <p className="card-header-title">
              <span className="icon">
                <i className="mdi mdi-account"></i>
              </span>
              Customers List
            </p>
            <a href="!#" className="card-header-icon">
              <span className="icon">
                <i className="mdi mdi-reload"></i>
              </span>
            </a>
          </header>

          <Table className="table is-striped is-fullwidth">
            <Thead>
              <Tr>
                <Th>No</Th>
                <Th>Nama Lengkap</Th>
                <Th>No. Telepon</Th>
                <Th>Alamat</Th>
                <Th>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {customers.map((customer, index) => (
                <Tr key={customer.uuid}>
                  <Td>{index + 1}</Td>
                  <Td>{customer.nama_lengkap}</Td>
                  <Td>{customer.no_telp}</Td>
                  <Td>{customer.alamat}</Td>
                  <Td>
                    <Link
                      to={`/customers/edit/${customer.uuid}`}
                      className="button is-small is-info">
                      Edit
                    </Link>
                    <button
                      onClick={() => confirmDeleteCustomer(customer.uuid)}
                      className="button is-small is-danger">
                      Delete
                    </button>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </div>
        <nav className="pagination" role="navigation" aria-label="pagination">
          <ul className="pagination-list">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              setCurrentPage={setCurrentPage}
            />
          </ul>
        </nav>
      </section>
    </div>
  );
};

export default CustomerList;
