import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import TitleBar from "../TitleBar";
import HeroBar from "../HeroBar";
import { addDivision } from "../../api/apiDivision.js";
import { fetchDepartments } from "../../api/apiDepartment.js"; // Import fungsi untuk mengambil daftar departemen
import Swal from "sweetalert2";

const FormAddDivision = () => {
  const [namaDivision, setNamaDivision] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [departments, setDepartments] = useState([]);
  const [msg, setMsg] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchDepartmentsData = async () => {
      try {
        const response = await fetchDepartments(1); // Ambil daftar departemen dari API
        setDepartments(response.departments || []);
      } catch (error) {
        console.error("Error fetching departments:", error);
      }
    };
    fetchDepartmentsData();
  }, []);

  const saveDivision = async (e) => {
    e.preventDefault();
    try {
      await addDivision({
        division_name: namaDivision,
        dept_id: selectedDepartment, // Gunakan ID departemen yang dipilih
      });
      navigate("/departments");
    } catch (error) {
      if (error) {
        setMsg("Error Saving Division");
      }
    }
  };

  return (
    <div>
      <TitleBar titles={["Home", "Divisions"]} />
      <HeroBar title="Add Division" />
      <section className="section is-main-section">
        <div className="container">
          <div className="card is-shadowless">
            <div className="card-content">
              <div className="content">
                <form onSubmit={saveDivision}>
                  <p className="has-text-centered">{msg}</p>
                  <div className="field">
                    <label className="label">Division Name</label>
                    <div className="control">
                      <input
                        type="text"
                        className="input"
                        value={namaDivision}
                        onChange={(e) => setNamaDivision(e.target.value)}
                        placeholder="Division Name"
                      />
                    </div>
                  </div>
                  <div className="field">
                    <label className="label">Select Department</label>
                    <div className="control">
                      <div className="select">
                        <select
                          value={selectedDepartment}
                          onChange={(e) =>
                            setSelectedDepartment(e.target.value)
                          }>
                          <option value="">Select Department</option>
                          {departments.map((department) => (
                            <option
                              key={department.dept_id}
                              value={department.dept_id}>
                              {department.dept_name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="field">
                    <div className="control">
                      <button type="submit" className="button is-success">
                        Save
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default FormAddDivision;
