import React from "react";

const Pagination = ({ currentPage, totalPages, setCurrentPage }) => {
  const renderPagination = () => {
    const pages = [];
    const displayPages = 5; // Menentukan berapa banyak halaman yang akan ditampilkan
    const totalPagesToShow = Math.min(displayPages, totalPages); // Menentukan total halaman yang akan ditampilkan

    // Menentukan halaman pertama dan terakhir yang akan ditampilkan
    let startPage = Math.max(1, currentPage - Math.floor(displayPages / 2));
    let endPage = startPage + totalPagesToShow - 1;

    // Jika halaman terakhir melebihi total halaman, sesuaikan halaman terakhir
    if (endPage > totalPages) {
      endPage = totalPages;
      startPage = Math.max(1, endPage - totalPagesToShow + 1);
    }

    // Tambahkan tombol "Previous" jika halaman saat ini bukan halaman pertama
    if (currentPage > 1) {
      pages.push(
        <li key="previous">
          <button
            className="pagination-previous"
            onClick={() => setCurrentPage(currentPage - 1)}>
            « Previous
          </button>
        </li>
      );
    }

    // Tambahkan tombol untuk langsung menuju halaman pertama
    if (startPage > 1) {
      pages.push(
        <li key="first">
          <button className="pagination-link" onClick={() => setCurrentPage(1)}>
            1
          </button>
        </li>
      );
      pages.push(
        <li key="ellipsis-first">
          <span className="pagination-ellipsis">...</span>
        </li>
      );
    }

    // Tambahkan nomor halaman
    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <li key={i}>
          <button
            className={`pagination-link ${
              currentPage === i ? "is-current" : ""
            }`}
            aria-label={`Goto page ${i}`}
            onClick={() => setCurrentPage(i)}>
            {i}
          </button>
        </li>
      );
    }

    // Tambahkan tombol untuk langsung menuju halaman terakhir
    if (endPage < totalPages) {
      pages.push(
        <li key="ellipsis-last">
          <span className="pagination-ellipsis">...</span>
        </li>
      );
      pages.push(
        <li key="last">
          <button
            className="pagination-link"
            onClick={() => setCurrentPage(totalPages)}>
            {totalPages}
          </button>
        </li>
      );
    }

    // Tambahkan tombol "Next" jika halaman saat ini bukan halaman terakhir
    if (currentPage < totalPages) {
      pages.push(
        <li key="next">
          <button
            className="pagination-next"
            onClick={() => setCurrentPage(currentPage + 1)}>
            Next »
          </button>
        </li>
      );
    }

    return pages;
  };

  return (
    <nav className="pagination" role="navigation" aria-label="pagination">
      <ul className="pagination-list">{renderPagination()}</ul>
    </nav>
  );
};

export default Pagination;
