import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import TitleBar from "../TitleBar";
import HeroBar from "../HeroBar";
import { fetchDepartments, deleteDepartment } from "../../api/apiDepartment.js";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import Pagination from "../../features/Pagination";

const DepartmentList = () => {
  const [departments, setDepartments] = useState([]); // Perhatikan penggunaan huruf kecil untuk konsistensi
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    getDepartments(currentPage);
  }, [currentPage]);

  const getDepartments = async (page) => {
    try {
      const { departments: departmentsData, total_pages: total } =
        await fetchDepartments(page);
      setDepartments(departmentsData || []); // Pastikan nilai default adalah array kosong
      setTotalPages(total || 1); // Pastikan nilai default adalah 1
    } catch (error) {
      console.error("Error fetching departments:", error);
      Swal.fire("Error!", "Failed to fetch departments.", "error"); // Menampilkan kesalahan kepada pengguna
    }
  };

  const confirmDeleteDepartment = async (departmentId) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    });

    if (result.isConfirmed) {
      deleteDepartment(departmentId)
        .then(() => {
          getDepartments(currentPage);
          Swal.fire("Deleted!", "Department has been deleted.", "success");
        })
        .catch((error) => {
          console.error("Error deleting department:", error);
          Swal.fire("Error!", "Failed to delete department.", "error");
        });
    }
  };

  return (
    <div>
      <TitleBar titles={["Home", "Departments"]} />
      <HeroBar title="Departments" />
      <section className="section is-main-section">
        <Link to="/departments/add" className="button is-primary mb-2">
          Add Department
        </Link>
        <div className="card has-table has-mobile-sort-spaced">
          <header className="card-header">
            <p className="card-header-title">
              <span className="icon">
                <i className="mdi mdi-account"></i>
              </span>
              Departments List
            </p>
            <a
              href="#"
              className="card-header-icon"
              onClick={() => getDepartments(currentPage)}>
              <span className="icon">
                <i className="mdi mdi-reload"></i>
              </span>
            </a>
          </header>

          <Table className="table is-striped is-fullwidth">
            <Thead>
              <Tr>
                <Th>No</Th>
                <Th>Nama Department</Th>
                <Th>Jumlah Divisi</Th>
                <Th>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {Array.isArray(departments) && departments.length > 0 ? (
                departments.map((department, index) => (
                  <Tr key={department.dept_id}>
                    <Td>{index + 1}</Td>
                    <Td>{department.dept_name}</Td>
                    <Td>{department.division_count}</Td>
                    <Td>
                      <Link
                        to={`/departments/${department.dept_id}/division`}
                        className="button is-small is-primary">
                        <i className="mdi mdi-plus"></i>
                      </Link>
                      <Link
                        to={`/departments/edit/${department.dept_id}`}
                        className="button is-small is-info">
                        <i className="mdi mdi-pencil"></i>
                      </Link>
                      <button
                        onClick={() =>
                          confirmDeleteDepartment(department.dept_id)
                        }
                        className="button is-small is-danger">
                        <i className="mdi mdi-trash-can-outline"></i>
                      </button>
                    </Td>
                  </Tr>
                ))
              ) : (
                <Tr>
                  <Td colSpan="3">No departments found.</Td>
                </Tr>
              )}
            </Tbody>
          </Table>
        </div>
        <nav className="pagination" role="navigation" aria-label="pagination">
          <ul className="pagination-list">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              setCurrentPage={setCurrentPage}
            />
          </ul>
        </nav>
      </section>
    </div>
  );
};

export default DepartmentList;
