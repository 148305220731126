import axios from "axios";

const baseApiUrl = process.env.REACT_APP_BASE_API_URL;

export const fetchSOPs = async (page, limit, divisionId) => {
  try {
    const response = await axios.get(`${baseApiUrl}/sops`, {
      params: { page, limit, division_id: divisionId },
    });
    return response.data; // Mengembalikan data SOPs yang diterima dari backend
  } catch (error) {
    console.error("Error fetching SOPs:", error);
    throw error;
  }
};

export const addSop = async (sopData) => {
  try {
    await axios.post(`${baseApiUrl}/sops`, sopData);
  } catch (error) {
    console.error("Error adding sop:", error);
    throw error;
  }
};

export const deleteSop = async (sopId) => {
  try {
    await axios.delete(`${baseApiUrl}/sops/${sopId}`);
  } catch (error) {
    console.error("Error deleting sop:", error);
    throw error;
  }
};
