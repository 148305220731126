import React, { useState, useEffect } from "react";
import TitleBar from "../TitleBar";
import HeroBar from "../HeroBar";
import Swal from "sweetalert2";
import ReactSelect from "react-select";
import { useNavigate } from "react-router-dom";
import { fetchUsersForList } from "../../api/apiUser";
import { fetchAllProducts } from "../../api/apiProduct";
import { fetchComplaintCategories } from "../../api/apiCategoryComplaint";
import { fetchTypeComplaints } from "../../api/apiTypeComplaint";
import axios from "axios";

const baseApiUrl = process.env.REACT_APP_BASE_API_URL;

const FormAddComplaintWithItem = () => {
  const [reportDate, setReportDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [complaintName, setComplaintName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [description, setDescription] = useState("");
  const [userDelegate, setUserDelegate] = useState("");
  const [items, setItems] = useState([
    {
      productId: "",
      quantity: "",
      notes: "",
      productionCode: "",
      selectedCategory: "",
      selectedProduct: null,
      photoComplaint: "",
      photoProductionProduct: "",
    },
  ]);

  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [msg, setMsg] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [userDelegates, setUserDelegates] = useState([]);
  const [typesRes, setTypesRes] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    fetchResources();
  }, []);

  const fetchResources = async () => {
    try {
      const [usersRes, productsRes, categoriesRes, typesRes] =
        await Promise.all([
          fetchUsersForList(),
          fetchAllProducts(),
          fetchComplaintCategories(),
          fetchTypeComplaints(),
        ]);
      setUserDelegates(usersRes);
      setProducts(productsRes);
      setCategories(categoriesRes);
      setTypesRes(typesRes);
    } catch (error) {
      console.error("Error fetching resources:", error);
    }
  };

  const productOptions = products.map(({ id, sku, name }) => ({
    value: id,
    label: `${sku} - ${name}`,
  }));

  const saveComplaint = async (e) => {
    e.preventDefault();

    if (!complaintName || !phoneNumber || !description) {
      Swal.fire("Error", "Please fill in all required fields", "error");
      return;
    }

    const formData = new FormData();
    formData.append("reportDate", reportDate);
    formData.append("complaintName", complaintName);
    formData.append("phoneNumber", phoneNumber);
    formData.append("address", address);
    formData.append("complaintTypeId", selectedType);
    formData.append("userIdDelegate", userDelegate);
    formData.append("description", description);
    formData.append("status", 0);

    formData.append("items", JSON.stringify(items));

    // Append files
    items.forEach((item, index) => {
      if (item.photoComplaint) {
        formData.append(`photoComplaint`, item.photoComplaint);
      }
      if (item.photoProductionProduct) {
        formData.append(`photoProductionProduct`, item.photoProductionProduct);
      }
    });

    console.log([...formData.entries()]);

    try {
      await axios.post(`${baseApiUrl}/complaintwithitem`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setMsg("Complaint created successfully");
      navigate("/complaints");
    } catch (error) {
      console.error("Error saving complaint:", error);
      Swal.fire("Error", "Failed to create complaint", "error");
    }
  };

  const handleItemChange = (index, key, value) => {
    const newItems = [...items];
    newItems[index][key] = value;
    setItems(newItems);
  };
  const handleProductChange = (selectedOption, index) => {
    const newItems = [...items];
    newItems[index].selectedProduct = selectedOption;
    newItems[index].productId = selectedOption.value;
    setItems(newItems);
  };

  const addNewItemRow = () => {
    setItems([
      ...items,
      {
        productId: "",
        quantity: "",
        notes: "",
        productionCode: "",
        selectedCategory: "",
        photoProductionProduct: "",
        photoComplaint: "",
      },
    ]);
  };

  const removeItemRow = (index) => {
    const newItems = [...items];
    newItems.splice(index, 1);
    setItems(newItems);
  };

  const [previewUrls, setPreviewUrls] = useState(
    Array.from({ length: items.length }, () => null)
  );
  const handleFileChange = (e, index, fieldName) => {
    const file = e.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = () => {
      const newPreviewUrls = [...previewUrls];
      newPreviewUrls[index] = reader.result;
      setPreviewUrls(newPreviewUrls);
    };
    reader.readAsDataURL(file);
    const newItems = [...items];
    newItems[index][fieldName] = file;
    setItems(newItems);
  };

  return (
    <div>
      <TitleBar titles={["Home", "Complaints"]} />
      <HeroBar title="Create Complaint" />
      <section className="section is-main-section">
        <div className="container">
          <div className="card is-shadowless">
            <div className="card-content">
              <div className="content">
                <form onSubmit={saveComplaint}>
                  <p className="has-text-centered">{msg}</p>
                  <div className="columns">
                    <div className="column">
                      <div className="field">
                        <label className="label">Sumber</label>
                        <div className="select is-hovered is-fullwidth">
                          <select
                            value={selectedType}
                            onChange={(e) => setSelectedType(e.target.value)}>
                            <option value="">Pilih Sumber Laporan...</option>
                            {typesRes.map(({ id, name }) => (
                              <option key={id} value={id}>
                                {name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="field">
                        <label className="label">Nama Sumber</label>
                        <div className="control">
                          <input
                            type="text"
                            className="input"
                            placeholder="Nama Pelanggan/Gudang/Outlet/Pameran"
                            value={complaintName}
                            onChange={(e) => setComplaintName(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="field">
                        <label className="label">Alamat</label>
                        <div className="control">
                          <input
                            type="text"
                            className="input"
                            placeholder="Alamat bisa dikosongkan apabila bukan pelanggan"
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="column">
                      <div className="field">
                        <label className="label">Tanggal Laporan</label>
                        <div className="control">
                          <input
                            type="date"
                            className="input"
                            value={reportDate}
                            onChange={(e) => setReportDate(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="field">
                        <label className="label">Nomor Telepon</label>
                        <div className="control">
                          <input
                            type="number"
                            className="input"
                            placeholder="0823xxxxxx"
                            value={phoneNumber}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="field">
                        <label className="label">User Delegasi</label>
                        <div className="control">
                          <div className="select is-hovered is-fullwidth">
                            <select
                              value={userDelegate}
                              onChange={(e) => setUserDelegate(e.target.value)}>
                              <option value="">Pilih User Delegasi...</option>
                              {userDelegates.map(({ id, name }) => (
                                <option key={id} value={id}>
                                  {name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <table className="table is-fullwidth">
                    <thead>
                      <tr>
                        <th>No.</th>
                        <th style={{ width: "20%" }}>Produk</th>
                        <th>Jumlah</th>
                        <th>Kategori</th>
                        <th>Catatan</th>
                        <th>Kode Produksi</th>
                        <th style={{ width: "2%" }}>Foto Kode Produksi</th>
                        <th style={{ width: "5%" }}>Foto Produk</th>
                        <th>
                          {" "}
                          <button
                            type="button"
                            className="button is-success is-small"
                            onClick={addNewItemRow}>
                            +
                          </button>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {items.map(
                        (
                          {
                            productId,
                            quantity,
                            notes,
                            selectedCategory,
                            productionCode,
                            selectedProduct,
                          },
                          index
                        ) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>
                              <div className="field">
                                <div className="control">
                                  <ReactSelect
                                    value={selectedProduct}
                                    onChange={(selectedOption) =>
                                      handleProductChange(selectedOption, index)
                                    }
                                    options={productOptions}
                                    placeholder="Pilih produk..."
                                  />
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="field">
                                <div className="control">
                                  <input
                                    type="number"
                                    placeholder="0"
                                    className="input is-small"
                                    name="quantity"
                                    value={quantity}
                                    onChange={(e) =>
                                      handleItemChange(
                                        index,
                                        "quantity",
                                        e.target.value
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="field">
                                <div className="control">
                                  <div className="select is-small is-hovered is-fullwidth">
                                    <select
                                      value={selectedCategory}
                                      onChange={(e) =>
                                        handleItemChange(
                                          index,
                                          "selectedCategory",
                                          e.target.value
                                        )
                                      }>
                                      <option value="">
                                        Pilih kategori...
                                      </option>
                                      {categories.map(({ id, name }) => (
                                        <option key={id} value={id}>
                                          {name}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="field">
                                <div className="control">
                                  <input
                                    type="text"
                                    className="input is-small"
                                    name="notes"
                                    value={notes}
                                    placeholder="Noted"
                                    onChange={(e) =>
                                      handleItemChange(
                                        index,
                                        "notes",
                                        e.target.value
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="field">
                                <div className="control">
                                  <input
                                    type="text"
                                    className="input is-small"
                                    name="productionCode"
                                    placeholder="Kode Produksi"
                                    value={productionCode}
                                    onChange={(e) =>
                                      handleItemChange(
                                        index,
                                        "productionCode",
                                        e.target.value
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="file is-small">
                                <label className="file-label">
                                  <input
                                    className=""
                                    type="file"
                                    name="photoProductionProduct"
                                    onChange={(e) =>
                                      handleFileChange(
                                        e,
                                        index,
                                        "photoProductionProduct"
                                      )
                                    }
                                  />
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className="file is-small">
                                <label className="file-label">
                                  <input
                                    className=""
                                    type="file"
                                    name="photoComplaint"
                                    onChange={(e) =>
                                      handleFileChange(
                                        e,
                                        index,
                                        "photoComplaint"
                                      )
                                    }
                                  />
                                </label>
                              </div>
                            </td>

                            <td>
                              {" "}
                              <button
                                type="button"
                                className="button is-danger is-small"
                                onClick={() => removeItemRow(index)}>
                                <i className="mdi mdi-trash-can-outline"></i>
                              </button>
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                  <div className="field">
                    <label className="label">Keterangan Tambahan</label>
                    <div className="control">
                      <textarea
                        className="textarea"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        placeholder="Keterangan Tambahan"
                      />
                    </div>
                  </div>
                  <div className="field">
                    <div className="control">
                      <button type="submit" className="button is-success">
                        Simpan
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default FormAddComplaintWithItem;
