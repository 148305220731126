import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import TitleBar from "../TitleBar";
import HeroBar from "../HeroBar";
import Swal from "sweetalert2";
import {
  deleteDivision,
  fetchDivisionsByDepartmentId,
} from "../../api/apiDivision.js";
import { fetchDepartments } from "../../api/apiDepartment.js";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import Pagination from "../../features/Pagination.js";

const DivisionList = () => {
  const [divisions, setDivisions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [departmentName, setDepartmentName] = useState("");
  const [departments, setDepartments] = useState([]);
  const [selectedDepartmentId, setSelectedDepartmentId] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchDivisionsByDepartmentId(
          selectedDepartmentId || id,
          currentPage // Gunakan currentPage sebagai nomor halaman yang diinginkan
        );
        setDivisions(response.divisions || []);
        setDepartmentName(response.departmentName || "");
      } catch (error) {
        console.error("Error fetching divisions:", error);
      }
    };
    fetchData();
  }, [id, selectedDepartmentId, currentPage]); // Perbarui useEffect untuk memperhitungkan currentPage

  useEffect(() => {
    const fetchDepartmentsData = async () => {
      try {
        const response = await fetchDepartments();
        setDepartments(response.departments || []);
      } catch (error) {
        console.error("Error fetching departments:", error);
      }
    };
    fetchDepartmentsData();
  }, []);

  const handleDepartmentChange = (e) => {
    setSelectedDepartmentId(e.target.value);
  };

  const confirmDeleteDivision = async (divisionId) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    });

    if (result.isConfirmed) {
      deleteDivision(divisionId)
        .then(() => {
          // Ketika menghapus division, perbarui halaman saat ini
          fetchDivisionsByDepartmentId(selectedDepartmentId || id, currentPage);
          Swal.fire("Deleted!", "Division has been deleted.", "success");
        })
        .catch((error) => {
          console.error("Error deleting division:", error);
          Swal.fire("Error!", "Failed to delete division.", "error");
        });
    }
  };

  return (
    <div>
      <TitleBar titles={["Home", "Departments"]} />
      <HeroBar title={`Division List ${departmentName}`}>
        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">Department:</label>
          </div>
          <div className="field-body">
            <div className="field">
              <div className="control">
                <div className="select">
                  <select
                    value={selectedDepartmentId}
                    onChange={handleDepartmentChange}>
                    <option value="">All Departments</option>
                    {departments.map((department) => (
                      <option
                        key={department.dept_id}
                        value={department.dept_id}>
                        {department.dept_name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </HeroBar>
      <section className="section is-main-section">
        <div className="container">
          <div className="card is-shadowless">
            <div className="card-content">
              <div className="content">
                <Link to="/divisions/add" className="button is-primary mb-2">
                  Add Division
                </Link>
                <Table className="table is-striped is-fullwidth">
                  <Thead>
                    <Tr>
                      <Th>No</Th>
                      <Th>Division Name</Th>
                      <Th>Actions</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {divisions.map((division, index) => (
                      <Tr key={division.division_id}>
                        <Td>{index + 1}</Td>
                        <Td>{division.division_name}</Td>
                        <Td>
                          <Link
                            to={`/divisions/edit/${division.division_id}`}
                            className="button is-small is-info">
                            <i className="mdi mdi-pencil"></i>
                          </Link>
                          <button
                            onClick={() =>
                              confirmDeleteDivision(division.division_id)
                            }
                            className="button is-small is-danger">
                            <i className="mdi mdi-trash-can-outline"></i>
                          </button>
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  setCurrentPage={setCurrentPage}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default DivisionList;
