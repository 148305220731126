import React from "react";
import "react-router-dom";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container-fluid">
        <div className="level">
          <div className="level-left">
            <div className="level-item">© 2024 Favori</div>
          </div>
          <div className="level-right">
            <div className="level-item">
              <div className="logo">Operational - System v.0.1</div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
