import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import TitleBar from "../TitleBar";
import HeroBar from "../HeroBar";

const baseApiUrl = process.env.REACT_APP_BASE_API_URL;
const FormEditComplaintCategory = () => {
  const [name, setName] = useState("");
  const [desc, setDesc] = useState("");
  const [msg, setMsg] = useState("");
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    const getProductById = async () => {
      try {
        const response = await axios.get(
          `${baseApiUrl}/complaint/category/${id}`
        );
        setName(response.data.name);
        setDesc(response.data.desc);
      } catch (error) {
        if (error.response) {
          setMsg(error.response.data.msg);
        }
      }
    };
    getProductById();
  }, [id]);

  const updateProduct = async (e) => {
    e.preventDefault();
    try {
      await axios.patch(`${baseApiUrl}/complaint/category/${id}`, {
        name: name,
        desc: desc,
      });
      navigate("/category-complaint");
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };

  return (
    <div>
      <TitleBar titles={["Home", "Category"]} />
      <HeroBar title="Edit Complaint Category" />
      <section className="section is-main-section">
        <div className="container">
          <div className="card is-shadowless">
            <div className="card-content">
              <div className="content">
                <form onSubmit={updateProduct}>
                  <p className="has-text-centered">{msg}</p>
                  <div className="field">
                    <label className="label">Name</label>
                    <div className="control">
                      <input
                        type="text"
                        className="input"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        placeholder="Category Name"
                      />
                    </div>
                  </div>
                  <div className="field">
                    <label className="label">Name</label>
                    <div className="control">
                      <input
                        type="text"
                        className="input"
                        value={desc}
                        onChange={(e) => setDesc(e.target.value)}
                        placeholder="Keterangan"
                      />
                    </div>
                  </div>
                  <div className="field">
                    <div className="control">
                      <button type="submit" className="button is-success">
                        Update
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default FormEditComplaintCategory;
