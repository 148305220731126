import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import TitleBar from "../TitleBar";
import HeroBar from "../HeroBar";

const baseApiUrl = process.env.REACT_APP_BASE_API_URL;

const FormAddComplaintCategory = () => {
  const [name, setName] = useState("");
  const [desc, setDesc] = useState("");
  const [msg, setMsg] = useState("");
  const navigate = useNavigate();

  const saveCategory = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${baseApiUrl}/complaint/category`, {
        name: name,
        desc: desc,
      });
      navigate("/category-complaint");
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };

  return (
    <div>
      <TitleBar titles={["Home", "Complaint"]} />
      <HeroBar title="Create Category Complaint" />
      <section className="section is-main-section">
        <div className="container">
          <div className="card is-shadowless">
            <div className="card-content">
              <div className="content">
                <form onSubmit={saveCategory}>
                  <p className="has-text-centered">{msg}</p>
                  <div className="field">
                    <label className="label">Name</label>
                    <div className="control">
                      <input
                        type="text"
                        className="input"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        placeholder="Category Name"
                      />
                    </div>
                  </div>
                  <div className="field">
                    <label className="label">Keterangan</label>
                    <div className="control">
                      <input
                        type="text"
                        className="input"
                        value={desc}
                        onChange={(e) => setDesc(e.target.value)}
                        placeholder="Keterangan"
                      />
                    </div>
                  </div>
                  <div className="field">
                    <div className="control">
                      <button type="submit" className="button is-success">
                        Save
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default FormAddComplaintCategory;
