import axios from "axios";

const baseApiUrl = process.env.REACT_APP_BASE_API_URL;

export const fetchComplaintCategories = async () => {
  try {
    const response = await axios.get(`${baseApiUrl}/complaint/category`);
    return response.data.categories;
  } catch (error) {
    console.error("Error fetching complaint categories:", error);
    throw error;
  }
};

export const fetchComplaintCategoriesList = async () => {
  try {
    const response = await axios.get(`${baseApiUrl}/complaint/category`);
    return response.data;
  } catch (error) {
    console.error("Error fetching complaint categories:", error);
    throw error;
  }
};

export const getComplaintCategoriesById = async (id) => {
  try {
    const response = await axios.get(`${baseApiUrl}/complaint/category/${id}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching product by ID:", error);
    throw error;
  }
};
