import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Dashboard from "./pages/dashboard/Dashboard";
import Login from "./components/Login";
import Users from "./pages/users/Users";
import Products from "./pages/products/Products";
import AddUser from "./pages/users/AddUser";
import EditUser from "./pages/users/EditUser";
import AddProduct from "./pages/products/AddProduct";
import EditProduct from "./pages/products/EditProduct";
import Customers from "./pages/customers/Customers";
import AddCustomer from "./pages/customers/AddCustomer";
import EditCustomer from "./pages/customers/EditCustomer";
import AddComplaintWithItemPage from "./pages/complaint/AddComplaint";
import Complaints from "./pages/complaint/Complaints";
import EditComplaint from "./pages/complaint/EditComplaint";
import PrintComplaint from "./components/Complaint/PrintComplaint";
import ComplaintCategory from "./pages/categorycomplaint/CategoryComplaint";
import AddComplaintCategory from "./pages/categorycomplaint/AddCategoryComplaint";
import EditComplaintCategory from "./pages/categorycomplaint/EditCategoryComplaint";
import Department from "./pages/departments/Department";
import AddDepartment from "./pages/departments/AddDepartment";
import EditDepartment from "./pages/departments/EditDepartment";
import Division from "./pages/divisions/Division";
import AddDivision from "./pages/divisions/AddDivision";
import EditDivision from "./pages/divisions/EditDivision";
import Sops from "./pages/sops/Sop";
import AddSops from "./pages/sops/AddSop.jsx";
import SopDivisions from "./pages/sops/SopDivision.jsx";
import NotFound from "./pages/404/NotFound.jsx";
import Overviews from "./pages/settings/Overviews.jsx";
import ApproveComplaint from "./pages/complaint/ApproveComplaint.jsx";

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/setting/overview" element={<Overviews />} />
          <Route path="*" element={<NotFound />} />

          <Route path="/users" element={<Users />} />
          <Route path="/users/add" element={<AddUser />} />
          <Route path="/users/edit/:id" element={<EditUser />} />

          <Route path="/products" element={<Products />} />
          <Route path="/products/add" element={<AddProduct />} />
          <Route path="/products/edit/:id" element={<EditProduct />} />

          <Route path="/category-complaint" element={<ComplaintCategory />} />
          <Route
            path="/category-complaint/add"
            element={<AddComplaintCategory />}
          />
          <Route
            path="/category-complaint/edit/:id"
            element={<EditComplaintCategory />}
          />

          <Route path="/customers" element={<Customers />} />
          <Route path="/customers/add" element={<AddCustomer />} />
          <Route path="/customers/edit/:id" element={<EditCustomer />} />

          <Route path="/complaints" element={<Complaints />} />
          <Route
            path="/complaints/add"
            element={<AddComplaintWithItemPage />}
          />
          <Route path="/complaints/edit/:id" element={<EditComplaint />} />
          <Route
            path="/complaints/preview/:id"
            element={<ApproveComplaint />}
          />
          <Route path="/complaints/print/:id" element={<PrintComplaint />} />

          <Route path="/departments" element={<Department />} />
          <Route path="/departments/add" element={<AddDepartment />} />
          <Route path="/departments/edit/:id" element={<EditDepartment />} />

          <Route path="/departments/:id/division" element={<Division />} />
          <Route path="/divisions/add" element={<AddDivision />} />
          <Route path="/divisions/edit/:id" element={<EditDivision />} />

          <Route path="/portalsop" element={<Sops />} />
          <Route path="/portalsop/division/:id" element={<SopDivisions />} />
          <Route path="/portalsop/add" element={<AddSops />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
