import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import TitleBar from "../TitleBar.jsx";
import HeroBar from "../HeroBar.jsx";
import { fetchSOPs, deleteSop } from "../../api/apiSop.js"; // tambahkan deleteSop
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import Pagination from "../../features/Pagination";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";

function SOPListDivision() {
  const { user } = useSelector((state) => state.auth);
  const { id } = useParams(); // Mendapatkan nilai id dari URL
  const [sops, setSOPs] = useState([]);
  const [divisionName, setDivisionName] = useState(""); // Menyimpan nama divisi
  const [currentPage, setCurrentPage] = useState(1); // Menyimpan halaman saat ini
  const [totalPages, setTotalPages] = useState(1); // Menyimpan total halaman

  // Efek samping untuk memuat data SOPs berdasarkan id divisi dan halaman
  useEffect(() => {
    const fetchData = async (page) => {
      try {
        const response = await fetchSOPs(page, 500, id);
        setSOPs(response.sops || []);
        setDivisionName(
          response.sops.length > 0
            ? response.sops[0].division.division_name
            : ""
        ); // Menyimpan nama divisi dari data SOPs
        setTotalPages(response.totalPages || 1); // Menyimpan total halaman
      } catch (error) {
        console.error("Error fetching SOPs:", error);
      }
    };
    fetchData(currentPage);
  }, [id, currentPage]);

  const handleDelete = async (sopId) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
    });

    if (result.isConfirmed) {
      try {
        await deleteSop(sopId);
        setSOPs(sops.filter((sop) => sop.sop_id !== sopId));
        Swal.fire("Deleted!", "Your SOP has been deleted.", "success");
      } catch (error) {
        console.error("Error deleting SOP:", error);
        Swal.fire("Error!", "There was an error deleting the SOP.", "error");
      }
    }
  };

  return (
    <div>
      <style>
        {`
          button.pagination-link.is-current {
            font-size: 18px !important;
          }
        `}
      </style>
      <TitleBar titles={["Home", "Portal SOP"]} />
      <HeroBar title={`Portal SOP Divisi ${divisionName}`} />{" "}
      {/* Menampilkan judul sesuai dengan nama divisi */}
      <section className="section is-title-bar">
        <Link to="/portalsop/add" className="button is-primary mb-2">
          Add SOPs
        </Link>
        <div className="columns is-multiline">
          {sops.map((sop) => (
            <div key={sop.sop_id} className="column is-3">
              <div className="folder">
                <div className="card">
                  <div className="card-content">
                    <div className="media">
                      <div className="media-content">
                        <p className="title is-4">{sop.title}</p>
                        <p className="subtitle is-6">
                          Division : {sop.division.division_name}
                        </p>
                        <p>Keterangan : {sop.description} </p>
                        <a
                          className="button is-primary"
                          href={sop.file_url}
                          target="_blank"
                          rel="noopener noreferrer">
                          Tampilkan File
                        </a>
                        {user && user.role === "admin" && (
                          <button
                            className="button is-danger"
                            onClick={() => handleDelete(sop.sop_id)}>
                            Delete
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        <nav className="pagination" role="navigation" aria-label="pagination">
          <ul className="pagination-list">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              setCurrentPage={setCurrentPage}
            />
          </ul>
        </nav>
      </section>
    </div>
  );
}

export default SOPListDivision;
