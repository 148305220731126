import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import TitleBar from "../TitleBar";
import HeroBar from "../HeroBar";
import { addSop } from "../../api/apiSop";
import { fetchDepartments } from "../../api/apiDepartment";

const FormAddSop = () => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [departmentId, setDepartmentId] = useState("");
  const [divisionId, setDivisionId] = useState("");
  const [file, setFile] = useState(null);
  const [msg, setMsg] = useState("");
  const [departmentList, setDepartmentList] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchDepartmentsData = async () => {
      try {
        const data = await fetchDepartments();
        setDepartmentList(data.departments || []);
      } catch (error) {
        console.error("Error fetching departments:", error);
      }
    };

    fetchDepartmentsData();
  }, []);

  const saveSop = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("title", title);
      formData.append("description", description);
      formData.append("division_id", departmentId);
      formData.append("file", file);

      await addSop(formData);
      navigate("/portalsop");
    } catch (error) {
      setMsg("Error saving SOP");
    }
  };

  return (
    <div>
      <TitleBar titles={["Home", "SOPs"]} />
      <HeroBar title="Create SOP" />
      <section className="section is-main-section">
        <div className="container">
          <div className="card is-shadowless">
            <div className="card-content">
              <div className="content">
                <form onSubmit={saveSop}>
                  <p className="has-text-centered">{msg}</p>
                  <div className="field">
                    <label className="label">Title</label>
                    <div className="control">
                      <input
                        type="text"
                        className="input"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        placeholder="SOP Title"
                      />
                    </div>
                  </div>
                  <div className="field">
                    <label className="label">Description</label>
                    <div className="control">
                      <input
                        type="text"
                        className="input"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        placeholder="Description"
                      />
                    </div>
                  </div>
                  <div className="field">
                    <label className="label">Department</label>
                    <div className="control">
                      <div className="select">
                        <select
                          value={departmentId}
                          onChange={(e) => setDepartmentId(e.target.value)}>
                          <option value="">Select Department</option>
                          {departmentList.map((department) => (
                            <optgroup
                              key={department.dept_id}
                              label={department.dept_name}>
                              {department.divisions.map((division) => (
                                <option
                                  key={division.division_id}
                                  value={division.division_id}>
                                  {division.division_name}
                                </option>
                              ))}
                            </optgroup>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="field">
                    <label className="label">File</label>
                    <div className="control">
                      <input
                        type="file"
                        className="input"
                        onChange={(e) => setFile(e.target.files[0])}
                      />
                    </div>
                  </div>

                  <div className="field">
                    <div className="control">
                      <button type="submit" className="button is-success">
                        Save
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default FormAddSop;
