import axios from "axios";

const baseApiUrl = process.env.REACT_APP_BASE_API_URL;

export const fetchTypeComplaints = async () => {
  try {
    const response = await axios.get(`${baseApiUrl}/typecomplaint`);
    return response.data.categories;
  } catch (error) {
    console.error("Error fetching type complaints:", error);
    throw error;
  }
};
