// HeroBar.js
import React from "react";

const HeroBar = ({ title }) => {
  return (
    <section className="hero is-hero-bar">
      <div className="hero-body">
        <div className="level">
          <div className="level-left">
            <div className="level-item">
              <h3 className="title">{title}</h3>
            </div>
          </div>
          <div className="level-right">
            <div className="level-item"></div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroBar;
