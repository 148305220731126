import axios from "axios";

const baseApiUrl = process.env.REACT_APP_BASE_API_URL;

export const fetchProductsCount = async () => {
  try {
    const response = await axios.get(`${baseApiUrl}/products`);
    return response.data.total_count;
  } catch (error) {
    console.error("Error fetching products count:", error);
    return 0;
  }
};

export const fetchAllProducts = async (searchTerm) => {
  try {
    const response = await axios.get(`${baseApiUrl}/allproducts`);
    return response.data;
  } catch (error) {
    console.error("Error fetching products:", error);
    throw error;
  }
};

export const fetchProducts = async (page, searchTerm) => {
  try {
    const response = await axios.get(
      `${baseApiUrl}/products?page=${page}&search=${searchTerm}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching products:", error);
    throw error;
  }
};

export const getProductById = async (productId) => {
  try {
    const response = await axios.get(`${baseApiUrl}/products/${productId}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching product by ID:", error);
    throw error;
  }
};

export const addProducts = async (productData) => {
  try {
    await axios.post(`${baseApiUrl}/products`, productData);
  } catch (error) {
    console.error("Error adding product:", error);
    throw error;
  }
};

export const updateProducts = async (productId, productData) => {
  try {
    await axios.patch(`${baseApiUrl}/products/${productId}`, productData);
  } catch (error) {
    console.error("Error updating product:", error);
    throw error;
  }
};

export const deleteProducts = async (productId) => {
  try {
    await axios.delete(`${baseApiUrl}/products/${productId}`);
  } catch (error) {
    console.error("Error deleting product:", error);
    throw error;
  }
};
