import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import TitleBar from "../TitleBar";
import HeroBar from "../HeroBar";
import { useSelector } from "react-redux";
import { fetchProducts, deleteProducts } from "../../api/apiProduct";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import Pagination from "../../features/Pagination";

const ProductList = () => {
  const { user } = useSelector((state) => state.auth);
  const [products, setProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    getProducts(currentPage, searchTerm);
  }, [currentPage, searchTerm]);

  const getProducts = async (page, searchTerm = "") => {
    try {
      const { products: productsData, total_pages: total } =
        await fetchProducts(page, searchTerm);
      setProducts(productsData);
      setTotalPages(total);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  const confirmDeleteProduct = async (productId) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    });

    if (result.isConfirmed) {
      deleteProductHandler(productId);
    }
  };

  const deleteProductHandler = async (productId) => {
    try {
      await deleteProducts(productId);
      getProducts(currentPage, searchTerm);
      Swal.fire("Deleted!", "Product has been deleted.", "success");
    } catch (error) {
      Swal.fire("Error!", "Failed to delete product.", "error");
    }
  };

  const renderProductNumber = (index) => {
    return (currentPage - 1) * 10 + index + 1;
  };

  return (
    <div>
      <TitleBar titles={["Home", "Products"]} />
      <HeroBar title="Products" />
      <section className="section is-main-section">
        <Link to="/products/add" className="button is-primary mb-2">
          Add Products
        </Link>
        <input
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Search products..."
          className="input mb-2"
        />
        <div className="card has-table has-mobile-sort-spaced">
          <header className="card-header">
            <p className="card-header-title">
              <span className="icon">
                <i className="mdi mdi-tag"></i>
              </span>
              Products List
            </p>
            <a href="#!" className="card-header-icon">
              <span className="icon">
                <i className="mdi mdi-reload"></i>
              </span>
            </a>
          </header>

          <Table className="table is-striped is-fullwidth">
            <Thead>
              <Tr>
                <Th>No</Th>
                <Th>SKU</Th>
                <Th>Name</Th>
                <Th>Price</Th>
                <Th>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {products.map((product, index) => (
                <Tr key={product.uuid}>
                  <Td>{renderProductNumber(index)}</Td>
                  <Td>{product.sku}</Td>
                  <Td>{product.name}</Td>
                  <Td>{product.price}</Td>
                  <Td>
                    <Link
                      to={`/products/edit/${product.uuid}`}
                      className="button is-small is-info">
                      Edit
                    </Link>
                    {user && user.role === "admin" && (
                      <button
                        onClick={() => confirmDeleteProduct(product.uuid)}
                        className="button is-small is-danger">
                        Delete
                      </button>
                    )}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </div>
        <nav className="pagination" role="navigation" aria-label="pagination">
          <ul className="pagination-list">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              setCurrentPage={setCurrentPage}
            />
          </ul>
        </nav>
      </section>
    </div>
  );
};

export default ProductList;
