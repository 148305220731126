import axios from "axios";

const baseApiUrl = process.env.REACT_APP_BASE_API_URL;

export const fetchDepartments = async (page) => {
  try {
    const response = await axios.get(`${baseApiUrl}/departments?page=${page}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching departments:", error);
    throw error;
  }
};

export const getDepartmentById = async (departmentId) => {
  try {
    const response = await axios.get(
      `${baseApiUrl}/departments/${departmentId}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching department by ID:", error);
    throw error;
  }
};

export const addDepartment = async (departmentData) => {
  try {
    await axios.post(`${baseApiUrl}/departments`, departmentData);
  } catch (error) {
    console.error("Error adding department:", error);
    throw error;
  }
};

export const updateDepartment = async (departmentId, departmentData) => {
  try {
    await axios.patch(
      `${baseApiUrl}/departments/${departmentId}`,
      departmentData
    );
  } catch (error) {
    console.error("Error updating department:", error);
    throw error;
  }
};

export const deleteDepartment = async (departmentId) => {
  try {
    await axios.delete(`${baseApiUrl}/departments/${departmentId}`);
  } catch (error) {
    console.error("Error deleting department:", error);
    throw error;
  }
};
