import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import TitleBar from "../TitleBar";
import HeroBar from "../HeroBar";
import { getProductById, updateProducts } from "../../api/apiProduct";

const FormEditProduct = () => {
  const [sku, setSku] = useState("");
  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [msg, setMsg] = useState("");
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const product = await getProductById(id);
        setSku(product.sku);
        setName(product.name);
        setPrice(product.price);
      } catch (error) {
        setMsg("Error fetching product data");
      }
    };
    fetchData();
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await updateProducts(id, { sku, name, price });
      navigate("/products");
    } catch (error) {
      setMsg("Error updating product");
    }
  };

  return (
    <div>
      <TitleBar titles={["Home", "Products"]} />
      <HeroBar title="Edit Products" />
      <section className="section is-main-section">
        <div className="container">
          <div className="card is-shadowless">
            <div className="card-content">
              <div className="content">
                <form onSubmit={handleSubmit}>
                  <p className="has-text-centered">{msg}</p>
                  <div className="field">
                    <label className="label">SKU</label>
                    <div className="control">
                      <input
                        type="text"
                        className="input"
                        value={sku}
                        onChange={(e) => setSku(e.target.value)}
                        placeholder="Product SKU"
                      />
                    </div>
                  </div>
                  <div className="field">
                    <label className="label">Name</label>
                    <div className="control">
                      <input
                        type="text"
                        className="input"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        placeholder="Product Name"
                      />
                    </div>
                  </div>
                  <div className="field">
                    <label className="label">Price</label>
                    <div className="control">
                      <input
                        type="text"
                        className="input"
                        value={price}
                        onChange={(e) => setPrice(e.target.value)}
                        placeholder="Price"
                      />
                    </div>
                  </div>

                  <div className="field">
                    <div className="control">
                      <button type="submit" className="button is-success">
                        Update
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default FormEditProduct;
