import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import TitleBar from "../TitleBar.jsx";
import HeroBar from "../HeroBar.jsx";
import { getDivisionById, updateDivision } from "../../api/apiDivision.js";
import { fetchDepartments } from "../../api/apiDepartment.js";

const FormEditDivision = () => {
  const [divisionName, setDivisionName] = useState("");
  const [departmentId, setDepartmentId] = useState(""); // Menggunakan departmentId sebagai state
  const [departments, setDepartments] = useState([]);
  const [msg, setMsg] = useState("");
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getDivisionById(id);
        setDivisionName(response.division_name || "");
        setDepartmentId(response.dept_id || "");
      } catch (error) {
        if (error.response) {
          setMsg(error.response.data.msg || "Error fetching Division data");
        } else {
          setMsg("Error fetching Division data");
        }
      }
    };
    fetchData();
  }, [id]);

  useEffect(() => {
    const fetchDepartmentsData = async () => {
      try {
        const response = await fetchDepartments();
        setDepartments(response.departments || []);
      } catch (error) {
        console.error("Error fetching departments:", error);
      }
    };
    fetchDepartmentsData();
  }, []);

  const handleUpdateDivision = async (e) => {
    e.preventDefault();
    try {
      await updateDivision(id, {
        division_name: divisionName,
        department_id: departmentId,
      });
      navigate(`/departments/${id}/division`);
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg || "Error updating Division");
      } else {
        setMsg("Error updating Division");
      }
    }
  };

  return (
    <div>
      <TitleBar titles={["Home", "Divisions"]} />
      <HeroBar title="Edit Division" />
      <section className="section is-main-section">
        <div className="container">
          <div className="card is-shadowless">
            <div className="card-content">
              <div className="content">
                <form onSubmit={handleUpdateDivision}>
                  <p className="has-text-centered has-text-danger">{msg}</p>
                  <div className="field">
                    <label className="label">Division Name</label>
                    <div className="control">
                      <input
                        type="text"
                        className="input"
                        value={divisionName}
                        onChange={(e) => setDivisionName(e.target.value)}
                        placeholder="Division Name"
                        required
                      />
                    </div>
                  </div>
                  <div className="field">
                    <label className="label">Department</label>
                    <div className="control">
                      <div className="select">
                        <select
                          value={departmentId}
                          onChange={(e) => setDepartmentId(e.target.value)}
                          required>
                          <option value="">Select Department</option>
                          {departments.map((department) => (
                            <option
                              key={department.dept_id}
                              value={department.dept_id}>
                              {department.dept_name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="field">
                    <div className="control">
                      <button type="submit" className="button is-success">
                        Update
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default FormEditDivision;
