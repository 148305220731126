import axios from "axios";

const baseApiUrl = process.env.REACT_APP_BASE_API_URL;

const formatDate = (dateString) => {
  const reportDate = new Date(dateString);
  const day = reportDate.getDate().toString().padStart(2, "0"); // Menambahkan nol di depan jika kurang dari 10
  const month = (reportDate.getMonth() + 1).toString().padStart(2, "0"); // Menambahkan nol di depan jika kurang dari 10
  const year = reportDate.getFullYear();
  return `${year}-${month}-${day}`;
};

export const fetchComplaintsCount = async () => {
  try {
    const response = await axios.get(`${baseApiUrl}/complaint`);
    return response.data.total_count;
  } catch (error) {
    console.error("Error fetching complaints count:", error);
    return 0;
  }
};

export const fetchStatusComplaintsCount = async (status) => {
  try {
    const response = await axios.get(
      `${baseApiUrl}/complaint?status=${status}`
    );
    return response.data.total_count;
  } catch (error) {
    console.error("Error fetching complaints count:", error);
    return 0;
  }
};

export const getComplaints = async (page) => {
  try {
    const response = await axios.get(`${baseApiUrl}/complaint?page=${page}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching complaints:", error);
    return { complaints: [], total_pages: 1 };
  }
};

export const deleteComplaint = async (complaintId) => {
  try {
    await axios.delete(`${baseApiUrl}/complaint/${complaintId}`);
    return true;
  } catch (error) {
    console.error("Error deleting complaint:", error);
    return false;
  }
};

export const fetchComplaintDetails = async (
  id,
  setReportDate,
  setComplaintName,
  setPhoneNumber,
  setAddress,
  setSelectedType,
  setSelectedUserDelegate,
  setDescription,
  setItems
) => {
  try {
    const response = await axios.get(`${baseApiUrl}/complaintwithitem/${id}`);
    const complaintData = response.data;
    setReportDate(formatDate(complaintData.complaint.reportDate));
    setComplaintName(complaintData.complaint.complaintName);
    setPhoneNumber(complaintData.complaint.phoneNumber);
    setAddress(complaintData.complaint.address);
    setSelectedType(complaintData.complaint.complaintTypeId);
    setSelectedUserDelegate(complaintData.complaint.userIdDelegate);
    setDescription(complaintData.complaint.description);
    setItems(complaintData.items);
  } catch (error) {
    console.error("Error fetching complaint details:", error);
  }
};

export const fetchComplaintDetailsPrint = async (
  id,
  setReportDate,
  setComplaintName,
  setPhoneNumber,
  setAddress,
  setSelectedType,
  setSumber,
  setDelegate,
  setSelectedUserDelegate,
  setDescription,
  setUserCreate,
  setItems
) => {
  try {
    const response = await axios.get(`${baseApiUrl}/complaintwithitem/${id}`);
    const complaintData = response.data;
    setReportDate(formatDate(complaintData.complaint.reportDate));
    setComplaintName(complaintData.complaint.complaintName);
    setPhoneNumber(complaintData.complaint.phoneNumber);
    setAddress(complaintData.complaint.address);
    setSelectedType(complaintData.complaint.complaintTypeId);
    setSumber(complaintData.complaint.complaint_type);
    setSelectedUserDelegate(complaintData.complaint.delegateUser);
    setDelegate(complaintData.complaint.delegateUser);
    setDescription(complaintData.complaint.description);
    setUserCreate(complaintData.complaint.userCreate);
    setItems(complaintData.items);
  } catch (error) {
    console.error("Error fetching complaint details:", error);
  }
};

export const saveComplaintWithItem = async (data) => {
  try {
    const response = await axios.post(`${baseApiUrl}/complaintwithitem`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error saving complaint:", error);
    throw error;
  }
};
