import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import TitleBar from "../TitleBar";
import HeroBar from "../HeroBar";
import { fetchDepartments } from "../../api/apiDepartment.js";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

function SOPList() {
  const { id } = useParams();
  const [departments, setDepartments] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchDepartments(id);
        setDepartments(response.departments || []);
      } catch (error) {
        console.error("Error fetching departments:", error);
      }
    };
    fetchData();
  }, [id]);
  const toggleFolder = (deptId) => {
    setDepartments(
      departments.map((department) =>
        department.dept_id === deptId
          ? { ...department, isOpen: !department.isOpen }
          : department
      )
    );
  };

  return (
    <div>
      <TitleBar titles={["Home", "Portal SOP"]} />
      <HeroBar title={`Portal SOP List`} />
      <section className="section is-title-bar">
        <Link to="/portalsop/add" className="button is-primary mb-2">
          Add SOPs
        </Link>
        <Link to="/sop-all" className="button is-success mb-2">
          Semua SOP
        </Link>
        <div className="columns is-multiline">
          {departments.map((department) => (
            <div key={department.dept_id} className="column is-3">
              <div className="folder">
                <div className="card">
                  <div className="card-content">
                    <div className="media">
                      <div className="media-content">
                        <p className="title is-4">{department.dept_name}</p>
                        <p className="subtitle is-6">
                          Division : {department.division_count}
                        </p>
                      </div>
                    </div>
                  </div>
                  <footer className="card-footer">
                    <button
                      className="card-footer-item"
                      onClick={() => toggleFolder(department.dept_id)}>
                      {department.isOpen ? "Collapse" : "Expand"}
                    </button>
                  </footer>
                </div>
                {department.isOpen && (
                  <div className="folder-content">
                    <Table>
                      <Thead>
                        <Tr>
                          <Th>No</Th>
                          <Th>Division</Th>
                          <Th>Action</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {department.divisions.map((division, index) => (
                          <Tr key={index}>
                            <Td>{index + 1}</Td>
                            <Td>{division.division_name}</Td>
                            <Td>
                              {" "}
                              <Link
                                to={`/portalsop/division/${division.division_id}`}
                                className="">
                                View
                              </Link>
                            </Td>
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
}

export default SOPList;
