import React from "react";

const TitleBar = ({ titles, history }) => {
  const handleClick = () => {
    history.push("/dashboard"); // Melakukan navigasi ke "/dashboard" saat judul "Home" diklik
  };

  return (
    <section className="section is-title-bar">
      <div className="level">
        <div className="level-left">
          <div className="level-item">
            <ul>
              {titles.map((title, index) => (
                <li key={index} onClick={title === "Home" ? handleClick : null}>
                  {title}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TitleBar;
