import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import TitleBar from "./TitleBar";
import HeroBar from "./HeroBar";
import { fetchCustomersCount } from "../api/apiCustomer";
import { fetchProductsCount } from "../api/apiProduct";
import {
  fetchComplaintsCount,
  fetchStatusComplaintsCount,
} from "../api/apiComplaint";

const Welcome = () => {
  const { user } = useSelector((state) => state.auth);
  const [customersCount, setCustomersCount] = useState(0);
  const [productsCount, setProductsCount] = useState(0);
  const [complaintsCount, setComplaintsCount] = useState(0);
  const [newcomplaintsCount, setNetComplaintsCount] = useState(0);
  const [approveCompalintManagerCount, setApproveComplaintCount] = useState(0);
  useEffect(() => {
    const fetchData = async () => {
      const customers = await fetchCustomersCount();
      const products = await fetchProductsCount();
      const complaints = await fetchComplaintsCount();
      const newcomplaints = await fetchStatusComplaintsCount(0);
      const approveComplaintManager = await fetchStatusComplaintsCount(2);

      setCustomersCount(customers);
      setProductsCount(products);
      setComplaintsCount(complaints);
      setNetComplaintsCount(newcomplaints);
      setApproveComplaintCount(approveComplaintManager);
    };

    fetchData();
  }, []);
  return (
    <>
      <TitleBar titles={["Home", "Dashboard"]} />
      <HeroBar title="Dashboard" />
      <section className="section is-main-section">
        <div className="tile is-ancestor">
          {/* <div className="tile is-parent">
            <div className="card tile is-child">
              <div className="card-content">
                <div className="level is-mobile">
                  <div className="level-item">
                    <div className="is-widget-label">
                      <h3 className="subtitle is-spaced">Customers</h3>
                      <h1 className="title">{customersCount}</h1>
                    </div>
                  </div>
                  <div className="level-item has-widget-icon">
                    <div className="is-widget-icon">
                      <span className="icon has-text-primary is-large">
                        <i className="mdi mdi-account-multiple mdi-48px"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          <div className="tile is-parent">
            <div className="card tile is-child">
              <div className="card-content">
                <NavLink to="/products" className="is-widget-link">
                  <div className="level is-mobile">
                    <div className="level-item">
                      <div className="is-widget-label">
                        <h3 className="subtitle is-spaced">Products</h3>
                        <h1 className="title">{productsCount}</h1>
                      </div>
                    </div>
                    <div className="level-item has-widget-icon">
                      <div className="is-widget-icon">
                        <span className="icon has-text-info is-large">
                          <span className="mdi mdi-tag mdi-48px"></span>
                        </span>
                      </div>
                    </div>
                  </div>
                </NavLink>
              </div>
            </div>
          </div>
          <div className="tile is-parent">
            <div className="card tile is-child">
              <div className="card-content">
                <NavLink to="/complaints" className="is-widget-link">
                  <div className="level is-mobile">
                    <div className="level-item">
                      <div className="is-widget-label">
                        <h3 className="subtitle is-spaced">New Complain</h3>
                        <h1 className="title">{newcomplaintsCount}</h1>
                      </div>
                    </div>
                    <div className="level-item has-widget-icon">
                      <div className="is-widget-icon">
                        <span className="icon has-text-warning is-large">
                          <span className="mdi mdi-alert mdi-48px"></span>
                        </span>
                      </div>
                    </div>
                  </div>
                </NavLink>
              </div>
            </div>
          </div>
          <div className="tile is-parent">
            <div className="card tile is-child">
              <div className="card-content">
                <NavLink to="/complaints" className="is-widget-link">
                  <div className="level is-mobile">
                    <div className="level-item">
                      <div className="is-widget-label">
                        <h3 className="subtitle is-spaced">Manager Approve</h3>
                        <h1 className="title">
                          {approveCompalintManagerCount}
                        </h1>
                      </div>
                    </div>
                    <div className="level-item has-widget-icon">
                      <div className="is-widget-icon">
                        <span className="icon has-text-success is-large">
                          <span className="mdi mdi-alert mdi-48px"></span>
                        </span>
                      </div>
                    </div>
                  </div>
                </NavLink>
              </div>
            </div>
          </div>
          <div className="tile is-parent">
            <div className="card tile is-child">
              <div className="card-content">
                <NavLink to="/complaints" className="is-widget-link">
                  <div className="level is-mobile">
                    <div className="level-item">
                      <div className="is-widget-label">
                        <h3 className="subtitle is-spaced">Total Complaint</h3>
                        <h1 className="title">{complaintsCount}</h1>
                      </div>
                    </div>
                    <div className="level-item has-widget-icon">
                      <div className="is-widget-icon">
                        <span className="icon has-text-danger is-large">
                          <span className="mdi mdi-alert mdi-48px"></span>
                        </span>
                      </div>
                    </div>
                  </div>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Welcome;
