import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import TitleBar from "../TitleBar";
import HeroBar from "../HeroBar";
import { fetchComplaintDetails } from "../../api/apiComplaint";
import { fetchUsersForList } from "../../api/apiUser";
import { fetchAllProducts } from "../../api/apiProduct";
import { fetchComplaintCategories } from "../../api/apiCategoryComplaint";
import { fetchTypeComplaints } from "../../api/apiTypeComplaint";

const baseApiUrl = process.env.REACT_APP_BASE_API_URL;

const FormApproveComplaint = () => {
  const [reportDate, setReportDate] = useState("");
  const [complaintName, setComplaintName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [description, setDescription] = useState("");
  const [status, setStatus] = useState("");
  const [SelectedUserDelegate, setSelectedUserDelegate] = useState("");
  const [userDelegates, setUserDelegates] = useState([]);
  const [selectedType, setSelectedType] = useState("");
  const [typesRes, setTypesRes] = useState([]);
  const [items, setItems] = useState([
    {
      productId: "",
      skuProduct: "",
      nameProduct: "",
      quantity: "",
      notes: "",
      complaintCategoryId: "",
      photoComplaint: "",
      photoProductionProduct: "",
    },
  ]);
  const [msg, setMsg] = useState("");
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    fetchResources();
    fetchComplaintDetails(
      id,
      setReportDate,
      setComplaintName,
      setPhoneNumber,
      setAddress,
      setSelectedType,
      setSelectedUserDelegate,
      setDescription,
      setItems
    );
  }, [id]);

  const fetchResources = async () => {
    try {
      const [usersRes, typesRes] = await Promise.all([
        fetchUsersForList(),
        fetchTypeComplaints(),
      ]);
      setUserDelegates(usersRes);
      setTypesRes(typesRes);
    } catch (error) {
      console.error("Error fetching resources:", error);
    }
  };

  const handleStatusChange = (e) => {
    setStatus(e.target.value);
  };

  const approveComplaint = async (e) => {
    e.preventDefault();

    if (!status) {
      alert("Please select a status");
      return;
    }

    try {
      await axios.patch(`${baseApiUrl}/complaintapprove/${id}`, {
        status,
      });
      setMsg("Complaint approved successfully");
      navigate("/complaints");
    } catch (error) {
      console.error("Error approving complaint:", error);
      alert("Failed to approve complaint");
    }
  };

  return (
    <div>
      <TitleBar titles={["Home", "Complaints"]} />
      <HeroBar title="Approve Complaint" />
      <section className="section is-main-section">
        <div className="container">
          <div className="card is-shadowless">
            <div className="card-content">
              <div className="content">
                <form onSubmit={approveComplaint}>
                  <p className="has-text-centered">{msg}</p>
                  <div className="columns">
                    <div className="column">
                      <div className="field">
                        <label className="label">Sumber</label>
                        <div className="select is-hovered is-fullwidth">
                          <select value={selectedType} disabled>
                            <option value="">Pilih Sumber Laporan...</option>
                            {typesRes.map(({ id, name }) => (
                              <option key={id} value={id}>
                                {name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="field">
                        <label className="label">Nama Sumber</label>
                        <div className="control">
                          <input
                            type="text"
                            className="input"
                            placeholder="Nama Pelanggan/Gudang/Outlet/Pameran"
                            value={complaintName}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="field">
                        <label className="label">Alamat</label>
                        <div className="control">
                          <input
                            type="text"
                            className="input"
                            placeholder="Alamat bisa dikosongkan apabila bukan pelanggan"
                            value={address}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div className="column">
                      <div className="field">
                        <label className="label">Tanggal Laporan</label>
                        <div className="control">
                          <input
                            type="date"
                            className="input"
                            value={reportDate}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="field">
                        <label className="label">Nomor Telepon</label>
                        <div className="control">
                          <input
                            type="text"
                            className="input"
                            placeholder="0823xxxxxx"
                            value={phoneNumber}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="field">
                        <label className="label">User Delegasi</label>
                        <div className="control">
                          <div className="select is-hovered is-fullwidth">
                            <select value={SelectedUserDelegate} disabled>
                              <option value="">Pilih User Delegasi...</option>
                              {userDelegates.map(({ id, name }) => (
                                <option key={id} value={id}>
                                  {name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="field">
                        <label className="label">Status Approve</label>
                        <div className="control">
                          <div className="select is-hovered is-fullwidth">
                            <select
                              value={status}
                              onChange={handleStatusChange}>
                              <option value="">Status Action</option>
                              <option value="2">Approve</option>
                              <option value="99">Ditolak</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <table className="table is-fullwidth">
                    <thead>
                      <tr>
                        <th>No.</th>
                        <th>Produk</th>
                        <th>Jumlah</th>
                        <th>Kategori</th>
                        <th>Catatan</th>
                        <th>Kode Produksi</th>
                        <th>Foto Kode Produksi</th>
                        <th>Foto Produk</th>
                      </tr>
                    </thead>
                    <tbody>
                      {items.map(
                        (
                          {
                            productId,
                            nameProduct,
                            quantity,
                            notes,
                            complaintCategoryId,
                            complaintCategoryName,
                            productionCode,
                            photoComplaint,
                            photoProductionProduct,
                          },
                          index
                        ) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>
                              <div className="field">
                                <div className="is-small is-hovered is-fullwidth">
                                  <span>{nameProduct}</span>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="field">
                                <div className="control">
                                  <span>{quantity}</span>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="field">
                                <div className="control">
                                  <div className="is-small is-hovered is-fullwidth">
                                    <span>{complaintCategoryName}</span>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="field">
                                <div className="control">
                                  <span>{notes}</span>
                                </div>
                              </div>
                            </td>
                            <td>{productionCode || "-"}</td>
                            <td>
                              <div className="image">
                                <img
                                  src={photoProductionProduct}
                                  alt="Production"
                                  style={{
                                    maxWidth: "150px",
                                    maxHeight: "150px",
                                  }}
                                />
                              </div>
                            </td>
                            <td>
                              <div className="image">
                                <img
                                  src={photoComplaint}
                                  alt="Complaint"
                                  style={{
                                    maxWidth: "150px",
                                    maxHeight: "150px",
                                  }}
                                />
                              </div>
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                  <div className="field">
                    <div className="control">
                      <button className="button is-link " type="submit">
                        Update
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default FormApproveComplaint;
