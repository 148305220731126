import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import TitleBar from "../TitleBar";
import HeroBar from "../HeroBar";
import {
  getDepartmentById,
  updateDepartment,
} from "../../api/apiDepartment.js";

const FormEditDepartment = () => {
  const [deptName, setDeptName] = useState("");
  const [msg, setMsg] = useState("");
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getDepartmentById(id);
        setDeptName(response.dept_name || "");
      } catch (error) {
        if (error.response) {
          setMsg(error.response.data.msg || "Error fetching department data");
        } else {
          setMsg("Error fetching department data");
        }
      }
    };
    fetchData();
  }, [id]);

  const handleUpdateDepartment = async (e) => {
    e.preventDefault();
    try {
      await updateDepartment(id, { dept_name: deptName });
      navigate("/departments");
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg || "Error updating department");
      } else {
        setMsg("Error updating department");
      }
    }
  };

  return (
    <div>
      <TitleBar titles={["Home", "Departments"]} />
      <HeroBar title="Edit Department" />
      <section className="section is-main-section">
        <div className="container">
          <div className="card is-shadowless">
            <div className="card-content">
              <div className="content">
                <form onSubmit={handleUpdateDepartment}>
                  <p className="has-text-centered has-text-danger">{msg}</p>
                  <div className="field">
                    <label className="label">Nama Department</label>
                    <div className="control">
                      <input
                        type="text"
                        className="input"
                        value={deptName}
                        onChange={(e) => setDeptName(e.target.value)}
                        placeholder="Nama Department"
                        required
                      />
                    </div>
                  </div>
                  <div className="field">
                    <div className="control">
                      <button type="submit" className="button is-success">
                        Update
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default FormEditDepartment;
