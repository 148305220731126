import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import TitleBar from "../TitleBar";
import HeroBar from "../HeroBar";
import { fetchComplaintCategoriesList } from "../../api/apiCategoryComplaint";
import Pagination from "../../features/Pagination";

const baseApiUrl = process.env.REACT_APP_BASE_API_URL;

const ComplaintCategoryList = () => {
  const [categories, setCategories] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    getComplaintCategories(currentPage);
  }, [currentPage]);

  const getComplaintCategories = async (page, searchTerm) => {
    try {
      const { categories: categoriesData, total_pages: total } =
        await fetchComplaintCategoriesList(page, searchTerm);
      setCategories(categoriesData);
      setTotalPages(total);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  const confirmDeleteCategory = async (categoryId) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    });

    if (result.isConfirmed) {
      deleteCategory(categoryId);
    }
  };

  const deleteCategory = async (categoryId) => {
    try {
      await axios.delete(`${baseApiUrl}/complaint/category/${categoryId}`);
      getComplaintCategories(currentPage);
      Swal.fire("Deleted!", "Category has been deleted.", "success");
    } catch (error) {
      console.error("Error deleting category:", error);
      Swal.fire("Error!", "Failed to delete category.", "error");
    }
  };

  const renderCategoryNumber = (index) => {
    return (currentPage - 1) * 10 + index + 1;
  };

  return (
    <div>
      <TitleBar titles={["Home", "Complaint"]} />
      <HeroBar title="Category" />
      <section className="section is-main-section">
        <Link to="/category-complaint/add" className="button is-primary mb-2">
          Add Category Complaint
        </Link>
        <div className="card has-table has-mobile-sort-spaced">
          <header className="card-header">
            <p className="card-header-title">
              <span className="icon">
                <i className="mdi mdi-tag"></i>
              </span>
              Category Complaint List
            </p>
            <a href="#!" className="card-header-icon">
              <span className="icon">
                <i className="mdi mdi-reload"></i>
              </span>
            </a>
          </header>

          <table className="table is-striped is-fullwidth">
            <thead>
              <tr>
                <th>No</th>
                <th>Category</th>
                <th>Keterangan</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {categories.map((category, index) => (
                <tr key={category.uuid}>
                  <td>{renderCategoryNumber(index)}</td>
                  <td>{category.name}</td>
                  <td>{category.desc}</td>
                  <td>
                    <Link
                      to={`/category-complaint/edit/${category.id}`}
                      className="button is-small is-info">
                      Edit
                    </Link>
                    <button
                      onClick={() => confirmDeleteCategory(category.id)}
                      className="button is-small is-danger">
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <nav className="pagination" role="navigation" aria-label="pagination">
          <ul className="pagination-list">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              setCurrentPage={setCurrentPage}
            />
          </ul>
        </nav>
      </section>
    </div>
  );
};

export default ComplaintCategoryList;
