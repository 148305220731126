import axios from "axios";

const baseApiUrl = process.env.REACT_APP_BASE_API_URL;

export const fetchDivisions = async (page) => {
  try {
    const response = await axios.get(`${baseApiUrl}/divisions?page=${page}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching divisions:", error);
    throw error;
  }
};

export const fetchDivisionsByDepartmentId = async (departmentId, page) => {
  try {
    const response = await axios.get(
      `${baseApiUrl}/divisions/department/${departmentId}?page=${page}`
    );
    const divisions = response.data.divisions || [];
    const departmentName =
      divisions.length > 0 ? divisions[0].department.dept_name : "";
    return { divisions, departmentName };
  } catch (error) {
    console.error("Error fetching departments:", error);
    throw error;
  }
};

export const getDivisionById = async (DivisionId) => {
  try {
    const response = await axios.get(`${baseApiUrl}/divisions/${DivisionId}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching division by ID:", error);
    throw error;
  }
};

export const addDivision = async (DivisionData) => {
  try {
    await axios.post(`${baseApiUrl}/Divisions`, DivisionData);
  } catch (error) {
    console.error("Error adding Division:", error);
    throw error;
  }
};

export const updateDivision = async (DivisionId, DivisionData) => {
  try {
    await axios.patch(`${baseApiUrl}/Divisions/${DivisionId}`, DivisionData);
  } catch (error) {
    console.error("Error updating Division:", error);
    throw error;
  }
};

export const deleteDivision = async (DivisionId) => {
  try {
    await axios.delete(`${baseApiUrl}/Divisions/${DivisionId}`);
  } catch (error) {
    console.error("Error deleting Division:", error);
    throw error;
  }
};
