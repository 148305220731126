import axios from "axios";

const baseApiUrl = process.env.REACT_APP_BASE_API_URL;

export const fetchUsers = async (page) => {
  try {
    const response = await axios.get(`${baseApiUrl}/users?page=${page}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching users:", error);
    throw error;
  }
};

export const fetchUsersForList = async (page) => {
  try {
    const response = await axios.get(`${baseApiUrl}/users?page=${page}`);
    return response.data.users;
  } catch (error) {
    console.error("Error fetching users:", error);
    throw error;
  }
};

export const fetchUsersForListDelegate = async (page) => {
  try {
    const response = await axios.get(`${baseApiUrl}/users/`);
    return response.data.users;
  } catch (error) {
    console.error("Error fetching users:", error);
    throw error;
  }
};

export const getUserById = async (userId) => {
  try {
    const response = await axios.get(`${baseApiUrl}/users/${userId}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching user details:", error);
    throw error;
  }
};

export const saveUser = async (userData) => {
  try {
    const response = await axios.post(`${baseApiUrl}/users`, userData);
    return response.data;
  } catch (error) {
    console.error("Error saving user:", error);
    throw error;
  }
};

export const updateUser = async (userId, userData) => {
  try {
    const response = await axios.patch(
      `${baseApiUrl}/users/${userId}`,
      userData
    );
    return response.data;
  } catch (error) {
    console.error("Error updating user:", error);
    throw error;
  }
};

export const deleteUser = async (userId) => {
  try {
    await axios.delete(`${baseApiUrl}/users/${userId}`);
  } catch (error) {
    console.error("Error deleting user:", error);
    throw error;
  }
};
