import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { IoLogOut, IoOptions, IoMail, IoPersonCircle } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { LogOut, reset } from "../features/authSlice";

const Navbar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);

  const [isActive, setIsActive] = useState(false);

  const [isSidebarActive, setIsSidebarActive] = useState(false);
  useEffect(() => {
    const html = document.documentElement;

    if (isSidebarActive) {
      html.classList.add("has-aside-mobile-expanded");
    } else {
      html.classList.remove("has-aside-mobile-expanded");
    }
  }, [isSidebarActive]);

  const toggleSidebar = () => {
    setIsSidebarActive(!isSidebarActive);
  };

  const toggleNavbar = () => {
    setIsActive(!isActive);
  };

  const logout = () => {
    dispatch(LogOut());
    dispatch(reset());
    navigate("/");
  };

  return (
    <nav id="navbar-main" className="navbar is-fixed-top">
      <div className="navbar-brand">
        <a
          className="navbar-item is-hidden-desktop jb-aside-mobile-toggle"
          onClick={toggleSidebar}>
          {" "}
          {/* Add onClick event handler */}
          <span className="icon">
            <i
              className={`mdi mdi-24px ${
                isSidebarActive ? "mdi-backburger" : "mdi-forwardburger"
              }`}></i>
          </span>
        </a>
        <div className="navbar-item has-control">
          <div className="control">
            <input placeholder="Search everywhere..." className="input" />
          </div>
        </div>
      </div>
      <div className="navbar-brand is-right">
        <a
          className="navbar-item is-hidden-desktop jb-navbar-menu-toggle"
          data-target="navbar-menu"
          onClick={toggleNavbar}>
          {" "}
          {/* Add onClick event handler */}
          <span className="icon">
            <i className="mdi mdi-dots-vertical"></i>
          </span>
        </a>
      </div>
      <div
        className={`navbar-menu fadeIn animated faster ${
          isActive ? "is-active" : ""
        }`}
        id="navbar-menu">
        <div className="navbar-end">
          <div className="navbar-item has-dropdown has-dropdown-with-icons has-divider has-user-avatar is-hoverable">
            <a className="navbar-link is-arrowless">
              <div className="is-user-avatar"></div>
              <div className="is-user-name">
                <span>{user && user.name}</span>
              </div>
              <span className="icon">
                <i className="mdi mdi-chevron-down"></i>
              </span>
            </a>
            <div className="navbar-dropdown">
              <a href="#" className="navbar-item">
                <span className="icon">
                  <IoPersonCircle></IoPersonCircle>
                </span>
                <span>My Profile</span>
              </a>
              <NavLink className="navbar-item" to="/setting/overview">
                <span className="icon">
                  <IoOptions></IoOptions>
                </span>
                <span>Settings</span>
              </NavLink>

              <a className="navbar-item">
                <span className="icon">
                  <IoMail></IoMail>
                </span>
                <span>Messages</span>
              </a>
              <hr className="navbar-divider" />
              <a className="navbar-item" onClick={logout}>
                <span className="icon">
                  <IoLogOut></IoLogOut>
                </span>
                <span>Log Out</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
