import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import TitleBar from "../TitleBar";
import HeroBar from "../HeroBar";
import { getComplaints, deleteComplaint } from "../../api/apiComplaint";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import Pagination from "../../features/Pagination";

// Fungsi untuk mengubah format tanggal dan waktu
const formatDate = (dateString) => {
  const reportDate = new Date(dateString);
  const day = reportDate.getDate();
  const month = reportDate.getMonth() + 1;
  const year = reportDate.getFullYear();
  const hours = reportDate.getHours();
  const minutes = reportDate.getMinutes();
  const seconds = reportDate.getSeconds();
  return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
};

const ComplaintList = () => {
  const { user } = useSelector((state) => state.auth);
  const STATUS = {
    0: "Waiting Approve",
    1: "Approve PIC",
    2: "Approve Manager",
    3: "Selesai",
    99: "Ditolak",
  };
  const STATUS_BADGE_CLASSES = {
    0: "is-warning",
    1: "is-info",
    2: "is-link",
    3: "is-success",
    99: "is-danger",
  };

  const [selectedComplaint, setSelectedComplaint] = useState(null);

  const handleViewComplaint = (complaint) => {
    setSelectedComplaint(complaint);
  };

  const handleClosePopup = () => {
    setSelectedComplaint(null);
  };

  const [complaints, setComplaints] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage]);

  const fetchData = async (page) => {
    const { complaints: complaintsData, total_pages: total } =
      await getComplaints(page);
    setComplaints(complaintsData);
    setTotalPages(total);
  };

  const confirmDeleteComplaint = async (complaintId) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    });

    if (result.isConfirmed) {
      const success = await deleteComplaint(complaintId);
      if (success) {
        fetchData(currentPage);
        Swal.fire("Deleted!", "Complaint has been deleted.", "success");
      } else {
        Swal.fire("Error!", "Failed to delete complaint.", "error");
      }
    }
  };

  const renderProductNumber = (index) => {
    return (currentPage - 1) * 10 + index + 1;
  };

  return (
    <div>
      <TitleBar titles={["Home", "Complaints"]} />
      <HeroBar title="Complaints" />
      <section className="section is-main-section">
        <Link to="/complaints/add" className="button is-primary mb-2">
          Add Complaints
        </Link>
        <div className="card has-table has-mobile-sort-spaced">
          <header className="card-header">
            <p className="card-header-title">
              <span className="icon">
                <i className="mdi mdi-account"></i>
              </span>
              Complaints List
            </p>
            <a href="" className="card-header-icon">
              <span className="icon">
                <i className="mdi mdi-reload"></i>
              </span>
            </a>
          </header>

          <Table className="table is-striped is-fullwidth">
            <Thead>
              <Tr>
                <Th>No</Th>
                <Th>Date Create</Th>
                <Th>Type</Th>
                <Th>Nama Pelapor</Th>
                <Th>No. Telepon</Th>
                <Th>Alamat</Th>
                <Th>Status</Th>
                <Th>Pembuat</Th>
                <Th>Store As</Th>
                <Th>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {complaints.map((complaint, index) => (
                <Tr key={complaint.id}>
                  <Td>{renderProductNumber(index)}</Td>
                  <Td>{formatDate(complaint.reportDate)}</Td>
                  <Td>{complaint.complaintType.name}</Td>
                  <Td>{complaint.complaintName}</Td>
                  <Td>{complaint.phoneNumber}</Td>
                  <Td>{complaint.address}</Td>
                  <Td>
                    <span
                      className={`tag ${
                        STATUS_BADGE_CLASSES[complaint.status]
                      }`}>
                      {STATUS[complaint.status]}
                    </span>
                  </Td>
                  <Td>{complaint.user.name}</Td>
                  <Td>{complaint.user.store}</Td>
                  <Td>
                    <ComplaintActions
                      complaint={complaint}
                      user={user}
                      handleViewComplaint={handleViewComplaint}
                      confirmDeleteComplaint={confirmDeleteComplaint}
                    />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>

          {/* Popup untuk melihat detail keluhan */}
          {selectedComplaint && (
            <div className="modal is-active">
              <div
                className="modal-background"
                onClick={handleClosePopup}></div>
              <div className="modal-content">
                <div className="box">
                  <h3 className="title is-3">Complaint Detail</h3>
                  <p>
                    <strong>Complaint Name:</strong>{" "}
                    {selectedComplaint.complaintName}
                  </p>
                  <p>
                    <strong>Phone Number:</strong>{" "}
                    {selectedComplaint.phoneNumber}
                  </p>
                  <p>
                    <strong>Address:</strong> {selectedComplaint.address}
                  </p>
                  <p>
                    <strong>Status:</strong>
                    <span
                      className={`tag ${
                        STATUS_BADGE_CLASSES[selectedComplaint.status]
                      } has-text-white ml-2`}>
                      {STATUS[selectedComplaint.status]}
                    </span>
                  </p>
                  <button className="button is-link" onClick={handleClosePopup}>
                    Close
                  </button>
                </div>
              </div>
              <button
                className="modal-close is-large"
                aria-label="close"
                onClick={handleClosePopup}></button>
            </div>
          )}
        </div>
        <nav className="pagination" role="navigation" aria-label="pagination">
          <ul className="pagination-list">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              setCurrentPage={setCurrentPage}
            />
          </ul>
        </nav>
      </section>
    </div>
  );
};

const ComplaintActions = ({
  complaint,
  user,
  handleViewComplaint,
  confirmDeleteComplaint,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleActionClick = (e, action) => {
    e.stopPropagation();
    action();
    setIsOpen(false); // Menutup dropdown setelah tindakan dilakukan
  };

  return (
    <div className={`dropdown ${isOpen ? "is-active" : ""}`}>
      <div className="dropdown-trigger">
        <button className="button is-small is-primary" onClick={toggleDropdown}>
          <span>Actions</span>
          <span className="icon is-small">
            <i className="mdi mdi-chevron-down"></i>
          </span>
        </button>
      </div>
      <div className="dropdown-menu" role="menu">
        <div className="dropdown-content">
          <Link
            to={`/complaints/print/${complaint.id}`}
            className="dropdown-item"
            target="__blank"
            onClick={(e) => handleActionClick(e, () => {})}>
            <i className="mdi mdi-printer"></i> Print
          </Link>
          {complaint.status === 0 && (
            <Link
              to={`/complaints/edit/${complaint.id}`}
              className="dropdown-item"
              onClick={(e) => handleActionClick(e, () => {})}>
              <i className="mdi mdi-pencil-outline"></i> Edit
            </Link>
          )}
          {complaint.status === 0 && (
            <Link
              to={`/complaints/preview/${complaint.id}`}
              className="dropdown-item"
              onClick={(e) => handleActionClick(e, () => {})}>
              <i className="mdi mdi-note"></i> Preview
            </Link>
          )}
          {user && user.role === "admin" && (
            <a
              onClick={(e) =>
                handleActionClick(e, () => confirmDeleteComplaint(complaint.id))
              }
              className="dropdown-item">
              <i className="mdi mdi-trash-can-outline"></i> Delete
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default ComplaintList;
